
.details, .pre-additional-questions-details {
  & { width: 95%; min-height: 630px; max-width: 956px; background: #fff; }
  h1 { width: auto; padding: 32px 33px 0; color: #343333; text-align: center; font-family: $primaryfont; font-size: 36px; font-style: normal; font-weight: 700; line-height: 34px; }
  p { padding: 0 15px; margin: 15px auto; color: #343333; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 34px; }
  .form {
    & { display: flex; justify-content: center; flex-wrap: wrap; margin: 21px auto 0px auto; width: 65%; }
    .form-detail { 
      * { box-sizing: border-box; }
      & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 48px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
      p { padding: 0; margin-top: 0px; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; line-height: 19px; color: #3B3A3A; }
      .MuiInput-root { font-family: $primaryfont !important; font-size: 18px; font-weight: 400; }
      .MuiInput-input { font-weight: 600; background-color: #F4F4F4; padding: 10px 15px; box-sizing: content-box; border-radius: 3px; }
      .MuiInput-input:focus { background-color: #FFF; }
      .MuiInput-input::placeholder { font-weight: 300; color: #868686; opacity: 1 !important; }
      .MuiSelect-select .first-menu { color: #fff; }
      .react-select__indicator {
        svg { color: #21165A; }
      }
      // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
      // .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
      .form-details-text { background-color: #F4F4F4; border-radius: 3px; width: 96%; }
      .form-details-text .MuiInput-root { padding: 0; border-radius: 3px; border: 1px solid transparent; }
      .form-details-email {
        .MuiInput-input { color: hsl(0, 0%, 20%); font-family: $primaryfont; font-size: 18px; font-style: normal; font-weight: 600; line-height: normal; }
      }
      .form-details-text .MuiInput-root.Mui-focused { border: 1px solid #3B3A3A; }
      
      
      .form-details-text.react-select div:nth-child(3) { border: none; }
      // .form-details-text.react-select div:nth-child(3) .react-select__control--is-focused {background-color: #FFFFFF; } 
      .form-details-text.react-select .react-select__value-container { padding: 7px 12px; }
      .form-details-text.react-select .react-select__value-container--has-value { background-color: white; border-radius: 3px; }
      .form-details-text.react-select .react-select__value-container--has-value ~ .react-select__indicators { background-color: white; border-radius: 3px; }
      .form-details-text.react-select .react-select__placeholder { white-space: nowrap; overflow-x: hidden; color: #868686; font-family: $primaryfont; font-size: 18px; font-style: normal; font-weight: 300; line-height: normal; }
      .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: $primaryfont; font-weight: 600; }
        // div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
      }

      .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
      .form-details-text {
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none; -moz-appearance: none; appearance: none;
        }
      }
      // .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
      .error-text { color: #E25B5B; font-family: $primaryfont; font-size: 10px; font-style: normal; font-weight: 400; line-height: normal; margin-top: 7px; }
      .required-field { color: #EA1C22; }
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:active{
          -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset;
      }
      input:-webkit-autofill:focus { background-color: #FFF; -webkit-box-shadow: 0 0 0 30px #FFFFFF inset; }
      .react-select__control  {border-color: none; }
    }
    
    .form-detail:last-child.school-name {
      flex: 0 1 100%; max-width: 100%; width: 100%;
    }
    .react-select__indicator-separator { width: 0px; }
  }

  .details-buttons { & { display: grid; grid-template-columns: 0.5fr 1fr 0.5fr; max-width: 92%; margin: 0 auto; padding-top: 2.2rem; padding-bottom: 3rem; } 
    .back-btn { & { align-self: center; } button { color: #444444; padding: 10px 15px; }
      button { font-family: $primaryfont; font-size: 18px; gap: 10px; }
    }
    .cta-btn {
      & { margin: 0 auto; text-decoration: none; align-self: center; }
      a { text-decoration: none; }
      button { font-family: $primaryfont; display: block; background: $color3; border-radius: 5px; color: #fff; text-decoration: none; min-width: 250px; width: 100%; max-width: 250px; text-align: center; font-size: 21px; font-weight: 700; letter-spacing: 1px; text-transform: uppercase; padding: 7px 15px; margin-left: 0; /* transition: 0.3s; */ }
      button:hover { background-color: #000; }
      button.Mui-disabled { background-color: #D8D8D8; color: #979797; }
      .button-progress { margin: 0 auto; }
    }
    .cta-btn-active {
      & { margin: 0 auto; text-decoration: none; align-self: center; }
      a { text-decoration: none; }
      button { display: block; background-color: $color3; border-radius: 5px; color: #fff; text-decoration: none; min-width: 250px; width: 100%; max-width: 250px; letter-spacing: 1px; padding: 7px 15px; margin-left: 0; /* transition: 0.3s; */ text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; letter-spacing: 1px; text-transform: uppercase; }
      button:hover { background-color: #000; color: #fff; }
      button.Mui-disabled { background-color: #D8D8D8; color: #979797; }
      .button-progress { margin: 0 auto; }
    }
  }
}

.details-container { & { padding: 15px 15px 15px; }
  .overall-score-subtext {
    & { width: auto; max-width: 90%; box-sizing: border-box; margin: auto; margin-bottom: 20px; margin-bottom: 50px; margin-top: 25px; }
    p { & { margin-top: 0; font-size: 21px; font-weight: 400; line-height: 28px; letter-spacing: 0px; text-align: center; color: $textcolor1; }
      span{ font-weight: bold; }
    }
  }
}

.details-container .additional-questions-container { & { width: 96%; max-width: 840px; margin: 45px auto 30px; }
  h2 {  }
  h3 {  }
  .answers { background-color: #F4F4F4; border-radius: 10px; padding: 15px; }
  .answers.last { background-color: #FFF; }
  .answers.last.just-slider { & { padding: 5px 15px; }
    .urgency h2 { margin: 5px auto; }
    .urgency .urgency-slider { padding: 15px 30px; padding-top: 15px !important; }
  }
  .answers-list { & { padding: 10px 10px 10px 35px; margin: 0 auto; }
    li { font-size: 18px; line-height: 24px; margin: 7px auto; }
  }
}

@media only screen and (max-width: 550px) {
  .details { & { width: 95%; margin: 15px auto; border-radius: 0px; }
    h1, main .pre-additional-questions-details h1 { font-size: 24px; margin: 0px; line-height: 38px; padding: 24px 9px 0px 8px; color: #000; }
    p { padding: 0; font-size: 18px; line-height: 26px; text-align: center;  width: 319px; font-weight: 400; padding: 0px 9px 0px 11px;  margin-bottom: 0px; }
    p br { content: ""; }
    p br:after { content: " "; }
    .form, main .pre-additional-questions-details .form { margin: 35px auto 0px; }
    .form .form-detail .form-details-text { padding-right: 0; width: 100%; min-height: 45px; }
    .details-buttons { & { width: 100%; max-width: 90%; display: flex; flex-direction: column-reverse; }
      .back-btn { align-self: flex-start; margin-top: 2rem; }
      .cta-btn { & { text-align: center; min-width: 250px; }
        button { min-width: 250px; width: 100%; max-width: 280px; padding: 12px 15px; color: #FFF; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 1px; text-transform: uppercase; background-color: #7CCEE7; } }
    }
  }

  .details .form .form-detail .form-details-text { background-color: #F4F4F4; }
  // .details .form .form-detail .form-details-text.react-select { max-width: 97.5%; }
  
  .details .form .form-detail .MuiInput-input { height: 22px; color: #000;  font-size: 18px; font-style: normal;  font-weight: 400;  line-height: normal; }
  .details-container { display: flex; flex-direction: column; justify-content:center; align-items: center; padding: 0px; }

}


@media only screen and (max-width: 650px) {
  .details-container .overall-score-subtext { 
    & { padding: 12px 2px; border-radius: 10px; box-sizing: border-box; max-width: 100%; }
    p { margin: 5px auto; font-size: 18px; line-height: 28px; }
  }
  .details h1, .pre-additional-questions-details h1 { color: #000; text-align: center; font-family: $primaryfont; font-size: 24px; font-style: normal; font-weight: 700; line-height: 38px; }
  .details-container .additional-questions-container { & { width: 96%; }
    h2 {  }
    h3 { text-align: center; }
    .answers { background-color: #F4F4F4; border-radius: 10px; padding: 10px; }
    .answers-list { & { padding: 10px 10px 10px 25px; }
      li { font-size: 16px; }
    }
  }
  .details, .pre-additional-questions-details {
    .form { & { width: 90%; } 
      .form-detail { & { flex: 0 1 100%; max-width: 100%; margin-bottom: 24px; }
        .input-title { max-width: 100%; font-size: 16px; font-weight: 700; color: #202945; margin: 0px 0px 5px 0px; }
        .error-text { margin: 0 auto -10px; }
      }
    }
  }
  .details .form .form-detail .required-field { color: #EA1C22; font-size: 16px; font-style: normal; font-weight: 700; line-height: normal; }
}



@media only screen and (max-width: 830px) {
  .details .form, .pre-additional-questions-details .form { width: 85%; }
}


