.intro {

  & { max-width: 1110px; width: 94%; border-radius: 10px; background: #FFF; margin:0px auto; margin-top: 48px; font-family: $primaryfont }
  .sub-container { display: flex; flex-direction: column; align-items:center ; padding: 37px 30px 30px 30px; }
  .assessment-intro { max-width: 990px; width: 100%; text-align: center; font-size: 24px; line-height: 35px; color: black; margin-bottom: 50px; }
  .intro-text {font-weight: 400;  }
  .italic-text {font-style: italic;}
 
  .middle-content {
    & { display: flex; flex-direction:column ; align-items: center; margin-bottom: 10px; margin-top: 5px; height: 70px;}
    .first {color: #7CCEE7; font-size: 24px;font-weight: 700;line-height: 35px; }
    .second {color: #565656; font-size: 24px;font-weight: 700;line-height: 35px; }
  }

  .bottom-content {
    & { display: flex; justify-content: center; align-items: center; flex-direction: column; max-width: 755px; width: 95%; }
    .heading { color: #565656; text-align: center; font-size: 18px; font-style: normal; font-weight: 400; line-height: 35px; }
    .points { color: #565656; font-size: 18px; font-style: italic; font-weight: 300; line-height: 35px; }
  }
 
  .step-buttons {
    & { width: 95%; display: flex; justify-content: space-between; }
    .prev-btn {
      & { align-self: center; }
      .cta-btn { padding: 9px 25px; color: #343333; font-size: 18px; font-family: $primaryfont; font-weight: 400; }
    }
    .cta { & { text-align: center; }
      .cta-btn {
        & { display: flex; min-width: 195px; justify-content: center; align-items: center; border: none; border-radius: 5px; margin: 45px auto 50px; padding: 12px 15px; text-decoration: none; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; text-align: center; background-color: #7CCEE7; color: white; font-size: 21px; letter-spacing: 1px; text-transform: uppercase; }
        &:hover { background: #000; transition: 0.3s; color: #fff; }
      }
    }
    .step-btn { max-width: 133px; width: 95%; }
  }

}

@media only screen and (max-width: 650px) {

  .intro .step-buttons { grid-template-columns: 1fr; grid-template-rows: repeat(2, 1fr); flex-direction: column-reverse; }
  .intro .step-btn { grid-area: item; }
  .intro .prev-btn { align-self: flex-start !important; }
  .intro .step-btn:nth-child(1) { grid-row: 2; }
  .intro .step-btn:nth-child(2) { grid-row: 1; }
  .intro .step-buttons .prev-btn .cta-btn { padding: 0; font-size: 16px; justify-content: flex-start; }
  .intro .step-buttons .cta .cta-btn { width: 100%; margin: 27px auto; }

}

@media only screen and (max-width: 550px) {  

  .intro .bottom-content .heading { font-size: 18px; line-height: 30px; }
  main .intro .sub-container .middle-content {
    .first { font-size: 21px; line-height: 30px; }
    .second { font-size: 21px; line-height: 30px; }
  }


}



@media only screen and (max-width: 830px) {

  

}

