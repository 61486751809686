.promo-container {
  & { margin: 75px auto; width: 100%; font-family: $primaryfont; color: #FFF; background-color: #1C5576; background-image: url('../../Assets/Images/promo-background.png'); background-size: cover; padding: 75px 45px; box-sizing: border-box; }
  .promo-container-heading h1 { margin: 0; text-align: center; font-size: 42px; font-style: normal; font-weight: 700; line-height: 43px; }
  .promo-subtext-logo-container {
    & { display: flex; align-items: center; justify-content: center; margin: 76px 0; gap: 7px; flex-direction: column; }
    .promo-logo {
      & { display: flex; align-items: center; gap: 7px; }
      .logo-text { font-weight: 700; font-size: 24px; margin: 0; }
    }
    .promo-text { font-size: 24px; font-weight: 400; }
  }
  .promo-content-container { 
    & { display: flex; justify-content: center; gap: 60px; }
    .promo-mobile-image-container {
      & { width: 50%; max-width: 590px; display: flex; justify-content: center; align-items: center; } 
      img { width: 100%; }
    }
    .promo-mobile-text-content-container { 
      & { width: 50%; max-width: 450px; }
      .promo-text { 
        & { font-size: 24px; font-weight: 400; line-height: 27.5px; letter-spacing: 1px; }
        p { margin: 46px 0; }
       }
      .promo-watch-video-btn {
        .play-btn-img {
          & { display: flex; justify-content: center; align-items: center; width: 100%; max-width: 280px; padding: 0; }
          a { text-decoration: none; background-color: #091C29; color: #FFF; font-size: 21px; font-weight: 700; text-transform: uppercase; border-radius: 30px; padding: 12px 15px; text-align: center; width: 100%; font-family: $primaryfont; letter-spacing: 2px; transition: all 0.2s; }
          a:hover { background-color: #7CCEE7; }
        }
      }
    }
  }
}

@media only screen and (max-width: 830px) {

  .promo-container { padding: 75px 30px; }
  .promo-container .promo-subtext-logo-container { & { flex-direction: column; margin: 20px auto; }
    .promo-logo {
      & { gap: 0; }
      .logo-text { font-size: 18px; font-style: normal; line-height: 27.5px; }
    }
    .promo-text { text-align: center; max-width: 32ch; font-size: 18px; font-weight: 700; line-height: 30px; }
  }
  .promo-container .promo-container-heading h1 { line-height: 30px; width: 70%; margin: auto; font-size: 21px; }
  .promo-container .promo-content-container { & { flex-direction: column; align-items: center; gap: 15px; }
    .promo-mobile-image-container { width: 100%; }
    .promo-mobile-text-content-container { & { width: 100%; max-width: 90%; }
      .promo-text { max-width: 50ch; }
      .promo-watch-video-btn { display: flex; justify-content: center; }
    }
  }

}

@media only screen and (max-width: 550px) {
  .promo-container { padding: 45px 28px; margin: 75px auto 30px; }
  .promo-container .promo-content-container .promo-mobile-text-content-container { max-width: 95%; }
  .promo-container .promo-content-container .promo-mobile-text-content-container .promo-text {
    & { font-size: 21px; }
    p { margin: 30px auto; font-size: 18px; }
  }
  .promo-container .promo-subtext-logo-container { margin: 28px auto; }
}


