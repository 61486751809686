

.start { 
  & { min-height: 520px; background: #DFF8FF; display: flex; justify-content: center; max-width: 1189px; width: 95%; border-radius: 10px; margin: 45px auto 0; font-family: $primaryfont; }
  .video {
    & { box-sizing: border-box; width: 47%; display: flex; justify-content: center; position: relative; border-radius: 10px 0 0 10px; background-color: #DFF8FF; object-fit: cover; transition: all 0.25s; overflow: hidden; max-width: 900px; }
    video { margin: 0px auto; position: relative; top: 0; left: 0; width: 100%; max-width: 900px; height: auto; height: max-content; height: auto; max-height: 560px; border-radius: 10px; }
    .thumbnail { position: absolute; height: 100%; width: 100%; overflow: hidden;background-color:#E5E5E5; object-fit: cover; }
    .play-btn-img { 
      & { position: absolute; /* top: 85%; */ top: 87.3%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 45px; width: 223px; border-radius: 25px; display: flex; align-items: center; justify-content: center; gap: 14px; width:223px; height: 45px; background: #17171799; }
      &>span { font-family: $primaryfont; font-size: 16px; font-weight: 600; line-height: 19px; text-transform: uppercase; color: #fff; }
    }

    .custom-controls { & { position: absolute; box-sizing: border-box; bottom: 10px; max-height: 45px; width: 100%; max-width: 530px; left: 50%; top: 86%; transform: translate(-50%, -50%); background-color: rgba(0, 0, 0, 0.5); padding: 5px; display: flex; align-items: center; justify-content: space-between; gap: 10px; z-index: 2; border-radius: 30px; }
      button { background-color: transparent; border: none; color: white; cursor: pointer; align-self: center; padding: 5px; display: flex; justify-content: center; align-items: center; margin: 0; margin-top: 2px; }
      input[type='range'] { width: 85%; height: 2px; -webkit-appearance: none; }
      input[type=range]::-webkit-slider-runnable-track { border-radius: 30px; }
      input[type=range]:hover::-webkit-slider-runnable-track { background-color: #fff; cursor: pointer; opacity: 0.95; }
      input[type='range']::-webkit-slider-thumb { -webkit-appearance: none; background-color: #71CAE6; border: none; width: 10px; height: 10px; border-radius: 50%; }
      span { color: white; font-size: 14px; min-width: 8ch; min-width: 70px; text-align: left; }

    }
  }
  .intro-txt {
    & { box-sizing: border-box; width: 53%; padding: 14px 34px 0 72px; display: flex; flex-direction: column; justify-content: center; transition: all 0.25s; position: relative; background-color: #FFFFFF; border-radius: 0 10px 10px 0; }
    h1 { font-family: $primaryfont; font-weight: 700; font-size: 30px; color: #000000; line-height: 50px; width: auto; margin: 0px auto 15px 0; text-align: left; }
    .intro-para { font-weight: 700; font-size: 18px; line-height:28.5px; color: #ED2028; text-align: center; }
    h2 { margin: 0; margin-bottom: 0; font-weight: 400; font-style: italic; color: #000000; font-size: 24px; line-height: 35px; max-width: 100%; }
    .intro-points-list { & { padding-left: 24px; margin: 30px 63px 15px 0; color: #565656; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 300; line-height: 35px; max-width: 100%; } 
    li { margin: 0px auto; }
      span { font-weight: 300; }
    }
    .intro-description {
      & { font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 48px;}
    }
    .completion { 
      & { width: 100%; display: flex; justify-content: space-between; align-items: center; text-align: center; margin: 5px auto 41px; color: #000; font-weight: 700; font-size: 18px; line-height: 27px; }
      .cta { background-color: #7CCEE7; border-radius: 5px; color: #fff; text-decoration: none; min-width: 250px; height: 50px; font-family: $primaryfont; font-size: 21px; font-weight: 700; line-height: 25px; letter-spacing: 1px; text-align: center; text-transform: uppercase; padding: 7px 15px; margin-left: 0; transition: 0.3s }
      .cta:hover { background-color: #000; transition: 0.3s; color: #fff; }
      .time-estimate {
        & { display: flex; justify-content: center; margin: 15px auto 15px 30px; }
        .clock-icon { width: 20px; height: 20px; margin-top: 3px; padding-right: 3px; }
      }
    }
    .footnote {
      & { position: absolute; color: #343333; bottom: 10px; font-size: 15px; margin: 5px auto; }
    }
    .para-container{ & {width: auto; text-align: center; padding-bottom: 15px; }
      .para { font-size: 14px; font-weight: normal; line-height: 24px; color: #343333; padding: 5px auto 30px; font-family: $primaryfont; }
    }
  }
    
}

.container.start { width: 95%; } 



@media only screen and (max-width: 830px) {
  .container.start { margin: 15px auto; }
  .start {
    & { flex-direction: column; gap: 10px; }
    .video { & { width: 100%; border-radius: 10px; max-height: 520px; }
      .custom-controls { max-width: 90%; height: 30px; top: 90%; }
      .playbtn { top: 75%; top: 82%; }
    }
    .intro-txt { 
      & { border-radius: 10px; width: 100%; margin: 0 auto; padding: 22px 21px 8px; }
      h1 { text-align: center; width: auto; font-size: 28px; line-height: 34px; margin: 0 auto 15px; max-width: 30ch; padding-top: 30px; }
      h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
      .intro-para { text-align: center; max-width: 35ch; margin: 5px auto; }
      .intro-points-list { width: auto; max-width: 46ch; margin: 15px auto; padding: 0 20px 0 40px; }
      .completion { 
        & { flex-direction: column; margin: 30px auto 0 auto; }
        .time-estimate { margin: 15px auto; }
        .cta { margin-bottom: 0; }          
      }
      .footnote { position: relative; bottom: 0; padding-top: 15px; }
    }
  }
  // .start .video video { width: 100%; } //margin: 0 -10%; object-fit: cover; }
  .start .video .thumbnail { width: 100%; height: 100%; object-fit: cover; }
  .container-home { 
    & { border-radius: 0; }
    .sub-container {
      & { padding: 22px 21px 18px; }
      .content-section {
        & { margin: 0; }
        .prayer-section { 
          & { font-size: 18px; margin-top: 22px; max-width: 272px; width: 99%; }
          .prayer-title { font-size: 18px; font-style: normal; font-weight: 700; line-height: 35.5px; }
         }
      }
      .test-btn-and-clock-container { & { flex-direction: column; gap: 4px; }
        .clock-section { margin-top: 0; }
      }
      .heading { font-size: 21px; line-height: 30px; margin: 0 20px; text-align: center; } 
      .heading-second { font-size: 18px; line-height: 25px; width: 92%; margin-top: 11px; box-sizing: border-box; text-align: center; }
    }
  }
}

@media only screen and (max-width: 650px) {
  // .start .video .playbtn { width: 144px; height: 144px; }
  .start { & {}
    .video .playbtn { width: 210px; height: auto; top: 85%; }
    .intro-txt {
      h1 { text-align: center; font-size: 24px; line-height: 28px; padding: 30px 10px 0; }
      h2 { text-align: $primaryfont; font-size: 21px; line-height: 27px; padding: 0 10px; width: auto; }
      // h1 br { content: ""; }
      h1 br:after { content: " "; }
    }
    .para-container p { font-size: 18px; }
  }
}

@media only screen and (max-width: 550px) {

  .start { margin-top: 15px; }
  .start .video { & { width: 100%; border-radius: 5px; min-height: 220px; height: 100%; }
    video { height: 100%; height: auto; width: 100%; }
    .close-video { right: 5px !important; top: 5px !important; }
    // .custom-controls { top: 80%; }
  }
  .start .intro-txt { & {  }
    h1 { font-size: 21px; line-height: 30px; max-width: 26ch; margin: 0 auto 15px; font-weight: 700; padding: 0 17px; }
    h2 { font-size: 18px; max-width: 34ch; }
    .intro-para { font-size: 14px; line-height: 20px; max-width: 28ch; }
    .intro-points-list { 
      & {  max-width: 45ch; padding: 0px 42px 0 30px; box-sizing: border-box; margin: 21px auto 15px; font-size: 18px; line-height: 37px; letter-spacing: 0px; text-align: left; }
    }
    .intro-description { font-family: $primaryfont; font-size: 14px; font-weight: 400; line-height: 30px; letter-spacing: 0px; text-align: center; width: 87%; margin: 14px auto; }
    .completion {
      img { margin: 0 4px 0 0; }
      span { font-weight: 700; }
    }
  }
  .start .intro-txt .intro-description { font-size: 16px; line-height: 34px; text-align: center; }
  .start .intro-txt .completion { & { margin: 15px auto 0; width: 100%; justify-content: center; }
    .time-estimate { margin-bottom: 21px; }
  }
  .start .intro-txt .completion .cta { margin: 0 auto; width: 90%; }

}

@media only screen and (max-width: 1000px) and (min-width: 831px) {
  .start .intro-txt {
    h1 { font-size: 28px; line-height: 40px; text-align: left; }
    .intro-points-list { font-size: 18px; }
    .completion { margin-top: 15px; }
  }
}

@media only screen and (min-width: 831px) {
  
}

@media only screen and (max-width: 1110px) {

}

