$color1: #009DF1;
$textcolor1: #343333;

$color1opacity30: #009DF130;
$color1opacity42: #009DF142;
$color1opacity60: #009DF160;

$color2: #17B582;
$color3: #7CCEE7;

$primaryfont: "Lato", sans-serif;
// $primaryfont: "Barlow", sans-serif;
// $primaryfont: "Proxima-Nova", sans-serif;
// $primaryfont: "Inter", sans-serif;
// $vesterbro: 'try-vesterbro-regular', sans-serif;
$vesterbro: 'try-vesterbro', sans-serif;