@import url("https://use.typekit.net/fvk4nfp.css"); // Futura PT & Lato Fonts
@import url("https://use.typekit.net/syk6jsc.css"); // Proxima Nova Font

// @import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,700&family=Montserrat:wght@100;200;300;400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

@font-face {
  font-family: "Butler"; font-weight: 900; font-style: normal;
  src: url("../Fonts/Butler-Black.woff2") format("woff2"),
    url("../Fonts/Butler-Black.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: bold; font-style: normal;
  src: url("../Fonts/Butler-Bold.woff2") format("woff2"),
    url("../Fonts/Butler-Bold.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: 800; font-style: normal;
  src: url("../Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("../Fonts/Butler-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: normal; font-style: normal;
  src: url("../Fonts/Butler.woff2") format("woff2"),
    url("../Fonts/Butler.woff") format("woff");
}

// @font-face{font-family:"try-vesterbro-regular";src:url("https://www.dafontfree.net/data/382/v/191590/TRYVesterbro-Regular.otf") format("woff"),url("https://www.dafontfree.net/data/382/v/191590/TRYVesterbro-Regular.otf") format("opentype"),url("https://www.dafontfree.net/data/382/v/191590/TRYVesterbro-Regular.otf") format("truetype");}

@font-face { 
  font-family: 'try-vesterbro'; font-weight: 900; font-style: normal; 
  src: url("../Fonts/Vesterbro/TRYVesterbro-Extrabold.otf") format("opentype"); 
}

@font-face { 
  font-family: 'try-vesterbro'; font-weight: 800; font-style: normal; 
  src: url("../Fonts/Vesterbro/TRYVesterbro-Bold.otf") format("opentype"); 
}

@font-face { 
  font-family: 'try-vesterbro'; font-weight: 300; font-style: normal; 
  src: url("../Fonts/Vesterbro/TRYVesterbro-Light.otf") format("opentype"); 
}

@font-face { 
  font-family: 'try-vesterbro'; font-weight: 500; font-style: normal; 
  src: url("../Fonts/Vesterbro/TRYVesterbro-Medium.otf") format("opentype"); 
}

@font-face { 
  font-family: 'try-vesterbro'; font-weight: 400; font-style: normal; 
  src: url("../Fonts/Vesterbro/TRYVesterbro-Regular.otf") format("opentype"); 
}